
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

*{
    margin: 0;
    padding:0;
    box-sizing: border-box!important;
}


.main_wrapper{
    background: #1B1B27;
    width: 100%;
    height: 100vh;
}

.main{
    width: 100%;
    height: 100%;
}
body {
    overflow: hidden;
}



.constructor {
    width: 100%;
}
.constructor_wrapper {
    width: 100%;
    /*max-width: 1920px;*/
    display: flex;
    align-items: flex-start;
}

.constructor_item1 {
    background: #2A2A3F;
    border-radius: 10px 10px 0px 0px;
    max-width: 270px;
    width: 100%;
    padding-top: 31px;
    padding-right: 21px;
    padding-left: 21px;
    margin-right: 30px;
    height: calc(100vh - 97px);
    /*min-height: 878px;*/
    margin-top: 5px;
}

.constructor_item_link {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /*line-height: 12px;*/
    color: #D8DFEA;
    display: block;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    margin-bottom: 22px;
}

.constructor_item_link:hover {
    color: #00C5F2!important;
}

#constructor_select .css-13cymwt-control {
     border-color: unset!important;
    border-radius: 10px!important;
     border-style: unset!important;
     border-width: unset!important;
    box-sizing: border-box;
    background: #dbe9fa!important;
}


#constructor_select .css-1u9des2-indicatorSeparator {
    display: none!important;
}

#constructor_select .css-1xc3v61-indicatorContainer {
    color: #323232!important;
}

#constructor_select .css-1dimb5e-singleValue {
    color: #323232!important;
}
#constructor_select .css-qbdosj-Input {
    color: #323232!important;
}

.constructor_item2 {
    width: 100%;
    max-width: 1569px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    padding-top: 30px;
    overflow: scroll;
    padding-bottom: 20px;
    height: calc(100vh - 97px);
}

.constructor_item2_child1_wrapper {
    width: 100%;
    max-width: 481px;
    margin-right: 30px;
}
.constructor_item2_child_box1 {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px 30px;
    margin-bottom: 30px;
    width: 100%;
}

.constructor_item2_child_box1_input_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    margin-bottom: 10px;
}

.constructor_item2_child_box1_input_field {
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    width: 100%;
    padding: 14px 20px;
    cursor: pointer;
    outline: none;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #BCBCBC;
    box-sizing: border-box;
}
.constructor_item2_child_box1_input_field::placeholder {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #BCBCBC;
}


.constructor_item2_child_box1_input_wrapper {
    width: 100%;
    margin-bottom: 20px;
}


.constructor_item2_child_box1_select_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    margin-bottom: 10px;
}

.constructor_item2_child_box1_select_wrapper {
    width: 100%;
}

.constructor_item2_child_box2 {
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    padding-top: 24px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.constructor_item2_child_box2_switch_box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.constructor_item2_child_box3_switch_box {
    display: flex;
    align-items: center;
    margin-bottom: 76px;
}


.constructor_item2_child_box2_switch_box_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    margin-right: 30px;

}
.input-switch {
    line-height: unset!important;
}
.input-switch.style-switch-one input[type="checkbox"] + label {
  width: 53px;
}
.input-switch.style-switch-one input[type="checkbox"] + label {
  width: 53px!important;
}

 .input-switch.style-switch-one input[type="checkbox"] + label:before {
    background-color: #d7d7d7;
    height: 30px!important;
}


 .input-switch.style-switch-one input[type="checkbox"] + label:after {
    left: -2px!important;
    top: -6px!important;
    width: 28px!important;
    height: 28px!important;
}


 .input-switch input[type="checkbox"] + label:before {
    top: -5px!important;
    left: 1px;
    bottom: 1px;
    right: -2px!important;
}

.input-switch.style-switch-one input[type="checkbox"]:checked + label:after {
    margin-left: 28px!important;
}

.input-switch.style-switch-one input[type="checkbox"]:checked + label {
    /* background-color: #0073da; */
    background-color: #0165E1!important;
}
.constructor_item2_child_box2_radio_inputs_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    margin-bottom: 20px;
    text-align: center;
}

.constructor_item2_child_box2_radio_inputs_fields_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.constructor_item2_child_box2_radio_inputs_field_label {
    display: flex;
    max-width: 107px;
    width: 100%;
    height: 232px;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}

.constructor_item2_child_box2_radio_inputs_field_label img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}




.container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    border: 2px solid #BCBCBC;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #0165E1;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #0165E1;
    border: 2px solid #0165E1;
}



/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    content: '';
    background-image: url("../svg/icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 10px;
    left: 50%;
    top: 9%;
    transform: translate(-50%, 50%)
}

.constructor_item2_child_box3{
    background: #FFFFFF;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    max-width: 605px;
}

.constructor_items_wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.constructor_item2_child_box3_select_wrapper_main {
    margin-bottom: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.constructor_item2_child_box3_select_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #323232;
    margin-bottom: 10px;
}

#constructor_select2 .css-13cymwt-control {
    border-color: #BCBCBC!important;
    border-radius: 10px!important;
    border-style: solid!important;
    border-width: 2px!important;
    box-sizing: border-box;
    background: #19194A!important;
}

#constructor_select2 {
    max-width: 250px;
    width: 100%;
    border-radius: 10px;

}

#constructor_select2:hover{
    background-color: #19194A!important;
    border-color: unset!important;
}

 #constructor_select2 .css-3evbh-control {
    border-radius: 10px;
}

#constructor_select3 .css-5wh67n-control {
    border-radius: 10px;
}

#constructor_select3 .css-1u9des2-indicatorSeparator {
    display: none!important;
}

#constructor_select3 .css-1xc3v61-indicatorContainer {
    color: #FFFFFF!important;
}

#constructor_select3 .css-1dimb5e-singleValue {
    color: #FFFFFF!important;
}
#constructor_select3 .css-qbdosj-Input {
    color: #FFFFFF!important;
}

#constructor_select3 .css-13cymwt-control {
    border-color: #BCBCBC!important;
    border-radius: 10px!important;
    border-style: solid!important;
    border-width: 2px!important;
    box-sizing: border-box;
    background: #00C5F2!important;
}

#constructor_select3 {
    max-width: 250px;
    width: 100%;
}

#constructor_select3:hover{
    background-color: #00C5F2!important;
    border-color: unset!important;
}

#constructor_select3 .css-1u9des2-indicatorSeparator {
    display: none!important;
}

#constructor_select3 .css-1u9des2-indicatorSeparator {
    display: none!important;
}

#constructor_select3 .css-1xc3v61-indicatorContainer {
    color: #FFFFFF!important;
}

#constructor_select3 .css-1dimb5e-singleValue {
    color: #FFFFFF!important;
}
#constructor_select3 .css-qbdosj-Input {
    color: #FFFFFF!important;
}


#constructor_select2 .css-1xc3v61-indicatorContainer {
    color: #FFFFFF!important;
}

#constructor_select2 .css-1dimb5e-singleValue {
    color: #FFFFFF!important;
}
#constructor_select2 .css-qbdosj-Input {
    color: #FFFFFF!important;
}

#constructor_select2  .css-oeb9do-control {
    border-radius: 10px!important;
}

#constructor_select3 .css-oeb9do-control {
    border-radius: 10px!important;
}

.constructor_item2_mobile_design {
    width: 100%;
    max-width: 395px;
    height: 800px;
    margin-left: 60px;
    margin-right: 57px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../img/mobile_border_img.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.constructor_item2_mobile_design_img {
    width: 100%;
    max-width: 395px;
    position: absolute;

}
.constructor_item2_mobile_design_wrapper_logo {
    width: 100%;
    height: 94px;
    /*margin: 0 auto;*/
    margin-bottom: 28px;
    display: flex;
}

.constructor_item2_mobile_design_wrapper_logo img {
    /*width: 100%;*/
    /*height: 100%;*/
    /*object-fit: cover;*/
}
.constructor_item2_mobile_design_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.constructor_item2_mobile_design_wrapper {
    background: #19194A;
    width: calc(100% - 50px);
    margin: auto auto;
    /*height: calc(100% - 41px);*/
    height: 95%;
    border-radius: 45px;
    padding-top: 80px;
    overflow: hidden;
    position: relative;
    z-index: 9999;
}
.constructor_item2_mobile_design_items_wrapper {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /*padding-left: 25px;*/
    /*padding-right: 25px;*/
    /*padding-top: 28px;*/
    overflow: scroll;
    /*height: calc(100% - 174px);*/
    height: 100%;
    padding-bottom: 20px;
    /*flex: 1;*/
}
.constructor_item2_mobile_design_item {
    /*width: 92px;*/
    width: 33%;
    margin: 0 auto;
    margin-bottom: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.constructor_item2_mobile_design_item_title_price_info_box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.constructor_item2_mobile_design_item_title {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /*line-height: 15px;*/
    color: #FFFFFF;
}
.constructor_item2_mobile_design_item_add_btn {
    background: #00C5F2;
    border-radius: 6px;
    width: 80px;
    height: 30px;
    border: unset;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #232323;
}

.constructor_item2_mobile_design_item_price_info {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    /*line-height: 15px;*/
    color: #FFFFFF;
}
.constructor_item2_mobile_design_item_img {
    width: 70px;
    height: 70px;
    background-color: #ffffff;
    margin-bottom: 7px;
    border-radius: 16px;
    overflow: hidden;
}
.constructor_item2_mobile_design_item_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.constructor_item2_child_box3_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.header_logo_link {
    display: flex;
    cursor: pointer;
    outline: none;
    width: 270px;
}
.constructor_item2_child_box3_select_wrapper {
    width: 46%;
}

/*media start*/
@media (min-width: 1617px) and (max-width: 1830px) {
    .constructor_item2_child_box2_radio_inputs_field_label {
        height: 200px!important;
    }

    .constructor_item2_child_box3_img {
        margin-right: 40px!important;
        margin-left: 40px!important;
        max-width: 351px!important;
        height: 735px!important;
    }
}
@media (min-width: 1546px) and (max-width: 1617px) {
    .constructor_item2_child_box2_radio_inputs_field_label {
        height: 180px!important;
    }

    .constructor_item2_child_box3_img {
        margin-right: 40px!important;
        margin-left: 40px!important;
        max-width: 351px!important;
        height: 735px!important;
    }
}
@media (min-width: 1501px) and (max-width: 1546px) {
    .constructor_item2_child_box2_radio_inputs_field_label {
        height: 180px!important;
    }

    .constructor_item2_child_box3_img {
        margin-right: 40px!important;
        margin-left: 40px!important;
        max-width: 340px!important;
        height: 700px!important;
    }
    .constructor_item1{
        max-width: 230px!important;
        margin-right: 25px!important;
    }
}
@media  (min-width: 1379px) and (max-width: 1500px) {
    .constructor_item2_child_box2_radio_inputs_field_label {
        height: 160px!important;
    }

    .constructor_item2_child_box3_img {
        margin-right: 30px!important;
        margin-left: 30px!important;
        max-width: 340px!important;
        height: 700px!important;
    }
    .constructor_item1{
        max-width: 230px!important;
        margin-right: 25px!important;
    }
}
@media (min-width: 1268px) and (max-width: 1379px) {
    .constructor_item2_child_box2_radio_inputs_field_label {
        height: 140px!important;
    }

    .constructor_item2_child_box3_img {
        margin-right: 25px!important;
        margin-left: 25px!important;
        max-width: 310px!important;
        height: 625px!important;
    }
    .constructor_item1{
        max-width: 230px!important;
        margin-right: 25px!important;
    }
}
@media (max-width: 1268px) {
    .constructor_item2_child_box2_radio_inputs_field_label {
        height: 120px!important;
    }

    .constructor_item2_child_box3_img {
        margin-right: 20px!important;
        margin-left: 20px!important;
        max-width: 276px!important;
        height: 560px!important;
    }
    .constructor_item1{
        max-width: 230px!important;
        margin-right: 25px!important;
    }
}


@media (max-width: 1267px) {
    .constructor_item2_child_box2_switch_box_title {
        font-size: 16px!important;
    }
}
/*media  end*/


@media (min-width: 1499px) and (max-width: 1661px) {
    .constructor_item2_mobile_design {
        margin-right: 35px!important;
        margin-left: 35px!important;
    }
}
@media  (max-width: 1498px) {
    .constructor_item2_mobile_design {
        margin-right: 25px!important;
        margin-left: 25px!important;
    }
}


@media (min-width: 1489px) and (max-width: 1613px) {
    .constructor_item2_mobile_design {
       height: 756px!important;
    }
}
@media (min-width: 1466px) and (max-width: 1488px) {
    .constructor_item2_mobile_design {
       height: 749px!important;
    }
}
@media  (max-width: 1466px) {
    .constructor_item2_mobile_design {
       height: 709px!important;
        max-width: 347px!important;
    }
}
