@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

.product_detail_wrapper {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 30px 30px 0 30px;
    cursor: pointer;
    width: 100%;
    /*margin-top: 30px;*/
    /*margin-right: 30px;*/
}
.product_detail_title_buttons_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #0165E1;
}

.product_detail_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;

}


.product_detail_add_edit_buttons_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product_detail_add_btn {
    background: #00C5F2;
    border-radius: 8px;
    width: 142px;
    padding-top: 9px;
    padding-bottom: 9px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 39px;
    margin-right: 30px;

}
.product_detail_add_btn_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin-right: 11px;
}

.product_detail_edit_btn {
    height: 39px;
    background: #0165E1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 168px;
    padding-top: 9px;
    padding-bottom: 9px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.product_detail_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.product_detail_item {
    padding: 9.5px 20px 9.5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: 1px solid #BCBCBC;
    border-radius: 8px;
    background-color: #ffffff;
    margin-bottom: 30px;
    margin-right: 20px;
    position: relative;

}

.product_detail_item:hover {
    background-color: #0165E1!important;
    /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
    border: 1px solid #0165E1;
}
.product_detail_item:hover .product_detail_item_title {
    color: #ffffff!important;
}
.product_detail_item_title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
}

.creating_new_feature_popup {
    /*display: none;*/
    position: fixed; /* Stay in place */
    z-index: 999999; /* Sit on top */
    left: 0;
    /*right: -1000px;*/
    /*transition: 0.5s;*/
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(40, 40, 56, 0.76);
    backdrop-filter: blur(2px);
    /*padding-top: 100px;*/
    /*padding-bottom: 100px;*/
    display: flex;
    justify-content: center;
    align-items: center;
}
.creating_new_feature_popup_wrapper {
    background: #FFFFFF;
    border-radius: 20px;
    width: 100%;
    max-width: 481px;
    margin: 0 auto;
    padding: 30px;
    position: relative;
}
.creating_new_feature_popup_close_btn {
    position: absolute;
    right: 20px;
    top: 20px;
    background: none;
    cursor: pointer;
    outline: none;
    border: none;
}

.creating_new_feature_popup_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    margin-bottom: 10px;

}
.creating_new_feature_popup_input_wrapper {
    width: 100%;
    margin-bottom: 20px;
}
.creating_new_feature_popup_input_field {
    width: 100%;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    padding: 14px 20px;
    cursor: pointer;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #BCBCBC;
}

.creating_new_feature_popup_input_field::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #BCBCBC;
}

.creating_new_feature_popup_buttons_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.creating_new_feature_popup_cancel_button {
    border: 1px solid #BCBCBC;
    background: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    height: 50px;
    width: 48%;
}

.creating_new_feature_popup_add_button {
    border: 1px solid #BCBCBC;
    background: #0165E1;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    height: 50px;
    width: 48%;
}

.product_detail_item_delete_btn {
    border-radius:100%;
    /*background: #BCBCBC;*/
    /*opacity: 0.32;*/
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    border: none;
    right: 2px;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product_detail_wrapper_main {
    width: 100%;
    margin-top: 30px;
    /* margin-right: 30px; */
    overflow: scroll;
    height: calc(100vh - 97px);
    padding-bottom: 50px;
    padding-right: 30px;
}

