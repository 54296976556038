
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');


*{
    margin: 0;
    padding:0;
}

.header{
    width: 100%;
}

.header_wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.header_child {
    /*width: 100%;*/
    flex: 1;
    background: #FFFFFF;
    border-bottom: 1px solid #BCBCBC;
    border-radius: 0px 0px 10px 10px;
    padding-top: 11px;
    padding-bottom: 13px;
    padding-left: 30px;
    padding-right: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}


.header_logo {
    cursor: pointer;
    padding-left: 17px;
    width: 270px;
}


.headers_navigation_links_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 840px;
    /*padding-right: 55px;*/
}

.headers_navigation_link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}
.headers_navigation_link:not(:last-child) {
    border-right: 1px solid #BCBCBC;
    padding-right: 20px;
    margin-right: 19px;
}


.headers_navigation_link_title {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #323232;
}


.headers_navigation_link_img {
    margin-bottom: 6px;
    height: 33px;
}

.header_switch_input_box {
    width: 185px;
}

.header_switch_notification_profile_buttons_wrapper {
    width: 100%;
    max-width: 640px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.header_volume_of_sales_btn {
    background: #19194A;
    border-radius: 60px;
    cursor: pointer;
    outline: none;
    width: 205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    color: #FFFFFF;
    height: 54px;

}

.header_volume_of_sales_btn_span1 {
    font-weight: 600!important;
    font-size: 18px!important;
}



.header_volume_of_sales_btn_span2 {
    font-weight: 400!important;
    font-size: 16px!important;
}




.header_notification_icon_info_wrapper {
    display: flex;
    align-items: center;
}



.header_notification_icon_info {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 76px;
    color: #323232;
    margin-left: 17px;
}


.header_notification_icon_info_span1 {
    font-weight: 600!important;
    font-size: 18px!important;
    margin-bottom: 6px;
    display: block;
}

.header_notification_icon_info_span2 {
    font-weight: 600!important;
    font-size: 16px!important;
    margin-bottom: 6px;
    /*display: block;*/
    margin-right: 3px;
}
.header_notification_icon_info_span5 {
    display: flex;
}

.header_notification_icon_info_span3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0165E1;
}

.header_profile_btn {
    width: 54px;
    height: 54px;
    background: #19194A;
    cursor: pointer;
    outline: none;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    color: #FFFFFF;
}



.input-switch {
    line-height: unset!important;
}
.input-switch.style-switch-one input[type="checkbox"] + label {
    width: 53px;
}
.input-switch.style-switch-one input[type="checkbox"] + label {
    width: 53px!important;
}

.input-switch.style-switch-one input[type="checkbox"] + label:before {
    background-color: #d7d7d7;
    height: 30px!important;
}


.input-switch.style-switch-one input[type="checkbox"] + label:after {
    left: -2px!important;
    top: -6px!important;
    width: 28px!important;
    height: 28px!important;
}


.input-switch input[type="checkbox"] + label:before {
    top: -5px!important;
    left: 1px;
    bottom: 1px;
    right: -2px!important;
}

.input-switch.style-switch-one input[type="checkbox"]:checked + label:after {
    margin-left: 28px!important;
}

.input-switch.style-switch-one input[type="checkbox"]:checked + label {
    /* background-color: #0073da; */
    background-color: #0165E1!important;
}

.hamburger_menu_btn {
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    display: none;
}


/*media start*/
@media (min-width: 1711px) and (max-width: 1810px) {
    .headers_navigation_links_wrapper {
        max-width: 790px !important;
    }

    .headers_navigation_link:not(:last-child) {
        padding-right: 10px!important;
    }
    .header_volume_of_sales_btn_span1 {
        font-weight: 600!important;
        font-size: 15px!important;
    }
    .header_volume_of_sales_btn_span2 {
        font-weight: 400!important;
        font-size: 14px!important;
    }
    .header_volume_of_sales_btn {
        width: 180px!important;
    }

    .header_notification_icon img {
        width: 40px!important;
        height: 40px!important;
    }
    .header_profile_btn {
        width: 50px!important;
        height: 50px!important;
        font-size: 38px!important;
    }
    .header_switch_notification_profile_buttons_wrapper {
        max-width: 600px!important;
    }
}
@media (min-width: 1611px) and (max-width: 1711px) {
    .headers_navigation_links_wrapper {
        max-width: 730px !important;
    }
    .headers_navigation_link_title {
        font-size: 11px!important;
    }

    .headers_navigation_link:not(:last-child) {
        padding-right: 10px!important;
    }
    .header_volume_of_sales_btn_span1 {
        font-weight: 600!important;
        font-size: 15px!important;
    }
    .header_volume_of_sales_btn_span2 {
        font-weight: 400!important;
        font-size: 14px!important;
    }
    .header_volume_of_sales_btn {
        width: 160px!important;
    }

    .header_notification_icon img {
        width: 40px!important;
        height: 40px!important;
    }
    .header_profile_btn {
        width: 50px!important;
        height: 50px!important;
        font-size: 38px!important;
    }
    .header_switch_notification_profile_buttons_wrapper {
        max-width: 570px!important;
    }
    .header_child {
        padding-left: 20px!important;
    }
}
@media (max-width: 1611px) {
    .header_switch_notification_profile_buttons_wrapper {
        display: none!important;
    }
    .mobile_header_switch_notification_profile_buttons_wrapper {
        width: 100%;
        /*max-width: 640px;*/
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .hamburger_menu_btn {
        display: block!important;
    }
    .headers_navigation_links_wrapper {
        max-width: 760px !important;
    }
    /*.headers_navigation_link_title {*/
    /*    font-size: 11px!important;*/
    /*}*/

    .headers_navigation_link:not(:last-child) {
        padding-right: 10px!important;
    }
    .header_volume_of_sales_btn_span1 {
        font-weight: 600!important;
        font-size: 16px!important;
    }
    .header_volume_of_sales_btn_span2 {
        font-weight: 400!important;
        font-size: 14px!important;
    }
    .header_volume_of_sales_btn {
        /*width: 160px!important;*/
        margin-bottom: 15px;
    }
    /*.header_notification_icon_info_wrapper {*/
    /*    margin-bottom: 15px;*/
    /*}*/
    .header_notification_icon_info {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px!important;
        /*width: 160px!important;*/
        /*align-items: baseline;*/
        color: #323232;
        margin-left: 17px;
        /*display: flex;*/
        /*justify-content: space-between;*/
    }
    .header_notification_icon_info_span1 {
        margin-bottom: unset!important;
        font-size: 16px!important;
    }
    .header_notification_icon_info_span2 {
        margin-bottom: unset!important;
        font-size: 15px!important;
    }

    .header_notification_icon img {
        width: 40px!important;
        height: 40px!important;
    }
    /*.header_profile_btn {*/
    /*    width: 50px!important;*/
    /*    height: 50px!important;*/
    /*    font-size: 38px!important;*/
    /*}*/
    .header_child {
        padding-left: 20px!important;
    }
    .mobile_menu {
        /*display: none;*/
        position: fixed; /* Stay in place */
        z-index: 999999999999999; /* Sit on top */
        left: 0;
        /*right: -1000px;*/
        /*transition: 0.5s;*/
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background:  rgba(23, 23, 23, 0.85);
    }
    .mobile_menu_wrapper {
        background-color: #FFFFFF;
        height: 100%;
        width: 280px;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
        padding-top: 75px;
        padding-left: 10px;
        overflow: scroll;
        padding-right: 10px;

    }
    .mobile_menu_close_btn {
        display: flex;
        cursor: pointer;
        outline: none;
        background: none;
        border: none;
        position: absolute;
        right: 20px;
        top: 20px;
    }
    .header_switch_input_box {
        width: 185px;
        margin-bottom: 16px;
    }

    .header_notification_profile_btn_main_wrapper {
        display: flex;
        justify-content: space-between;
        width: 210px;
    }
}
/*media end*/
.headers_navigation_link_img:hover {
    fill: #0165E1
}


@media (max-width: 1546px) {
    .header_logo_link {
        width: 230px!important;
    }
    .header_logo {
        width: 230px!important;
    }
}
