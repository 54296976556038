.product_categories_items_main_wrapper {
    width: 100%;
    /*margin-top: 30px;*/
    /*margin-right: 30px;*/
    background: #FFFFFF;
    border-radius: 20px;
    padding: 30px;
}


.product_categories_items_title_edit_btn_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    /*margin-bottom: 30px;*/
    border-bottom: 1px solid #0165E1;

}
.product_categories_item_main_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;

}

.product_categories_edit_btn {
    background: #0165E1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 39px;
    cursor: pointer;
    outline: none;
    border: none;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.product_categories_items_wrapper {
    width: 100%;
}
.product_categories_category_items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #0165E1;
    /*margin-bottom: 30px;*/
    padding-bottom: 20px;
    padding-top: 30px;
}

.product_categories_category_item {
    border: 1px solid #BCBCBC;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 30px;
    margin-right: 20px;
    position: relative;
}
.product_categories_subs_item_child {
    border: 1px solid #BCBCBC;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 30px;
    margin-right: 20px;
    position: relative;
    display: flex;
}


.product_categories_subs_item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #0165E1;
    /*margin-bottom: 30px;*/
    padding-top: 30px;
    padding-bottom: 20px;
}

.product_categories_subs_item_child_wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.product_categories_subs_item_child_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
}

.product_categories_category_item_name {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #323232;
}

.product_categories_add_btn {
    background: #00C5F2;
    border-radius: 8px;
    width: 168px;
    height: 39px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}



.product_categories_items_main_wrapper_parent {
    width: 100%;
    margin-top: 30px;
    /* margin-right: 30px; */
    overflow: scroll;
    height: calc(100vh - 97px);
    padding-bottom: 70px;
    padding-right: 30px;
}


/*media*/
@media (min-width: 1441px) and (max-width: 1620px) {
    .product_categories_category_item {
        margin-bottom: 25px!important;
        margin-right: 15px!important;
        padding: 10px 15px!important;

    }
    .product_categories_subs_item_child {
        margin-bottom: 25px!important;
        margin-right: 15px!important;
        padding: 10px 15px!important;
    }
    .product_detail_item {
        margin-bottom: 25px!important;
        margin-right: 15px!important;
        padding: 10px 15px!important;
    }
}
@media  (max-width: 1440px) {
    .product_categories_category_item {
        margin-bottom: 20px !important;
        margin-right: 10px !important;
        padding: 10px 15px !important;

    }

    .product_detail_item {
        margin-bottom: 20px !important;
        margin-right: 10px !important;
        padding: 10px 15px !important;
    }
    .product_categories_subs_item_child {
        margin-bottom: 20px !important;
        margin-right: 10px !important;
        padding: 10px 15px !important;
    }


    .product_categories_category_item_name {
        font-size: 14px !important;
    }

    .product_categories_subs_item_child_title {
        font-size: 14px !important;

    }

    .product_categories_items_title_edit_btn_wrapper {
        padding-bottom: 25px!important;
    }
    .product_detail_item_title {
        font-size: 14px !important;

    }
    .product_detail_title_buttons_wrapper {
        padding-bottom: 25px!important;

    }
}
