@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

.messages_item1 {
    background: #2A2A34;
    border-radius: 10px 10px 0px 0px;
    max-width: 451px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-right: 229px;
    height: calc(100vh - 80px);
    /*min-height: 878px;*/
    /*margin-top: 5px;*/
    overflow: auto;
}
.messages_search_input_icon_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #FFFFFF;
    border-radius: 39px;
    padding: 14px 20px;
    margin-bottom: 30px;
    margin-right: 30px;
    margin-left: 30px;
    height: 50px;
}

.messages_search_input {
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    opacity: 0.5;
    width: 90%;
}

.messages_search_input::placeholder {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    opacity: 0.5;
}
.messages_search_input_icon {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.messages_dialogs_tabs_main_wrapper {
    width: 100%;
}

.messages_dialogs_tabs_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
    /*padding-bottom: 20px;*/
    margin-bottom: 10px;
    border-bottom: 2px solid #FFFFFF;
}

.messages_dialogs_tab {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-bottom: 20px;
    /*height: 50px;*/
    position: relative;

}

.messages_dialogs_tab:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #2B78E4;
    opacity: 0;
    z-index: 9;
    bottom: -2px;
}
.messages_dialogs_tab_active {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-bottom: 20px;
    /*height: 50px;*/
    position: relative;
}
.messages_dialogs_tab_active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #2B78E4;
    opacity: 1;
    z-index: 9;
    bottom: -2px;
}

.messages_dialogs_tab:hover:after{
  opacity: 1!important;
}

.messages_dialog {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 30px;
    margin-bottom: 20px;
    align-items: flex-start;
}
.messages_dialog_img_name_message_wrapper {
    display: flex;
    align-items: flex-start;
}
.messages_dialog_img_online_icon_box {
    position: relative;
    margin-right: 10px;
}
.messages_dialog_img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
}

.messages_dialog_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.messages_dialog_online_icon {
    background: #6CCB5F;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    position: absolute;
    right: 2px;
    bottom: 4px;
}
.messages_dialog_name {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.messages_dialog_text_img_wrapper {
    display: flex;
    align-items: center;
}

.messages_dialog_text_img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.messages_dialog_text_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.messages_dialog_text {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #888888;
}

.messages_dialog_date_quantity_pin_icon_wrapper {
    width: 69px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.messages_dialog_date_info {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #838383;
    margin-bottom: 5px;
}


.messages_dialog_quantity_info_pin_icon_box {
    display: flex;
    align-items: center;
     justify-content: flex-end;
    width: 100%;
}

.messages_dialog_quantity_info_box {
    width: 34px;
    height: 23px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-right: 15px;*/
}

.messages_dialog_quantity_info {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
}

.messages_dialog_pin_icon {
    margin-left: 15px;
}

.messages_items_main_wrapper {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    width: 100%;
    max-width: 1011px;
    padding: 18px 0 22px 0;
    /*height: 800px;*/
    background: #2A2A34;


}



.messages_items_main_wrapper_parent {
    width: 100%;
    margin-top: 30px;
    /* margin-right: 30px; */
    /*overflow: scroll;*/
    /*height: calc(100vh - 115px);*/
    height: 100%;
    /*overflow: hidden;*/
    margin-bottom: 100px;
    padding-right: 229px;
}


.messages_items_main_wrapper_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding-right: 44px;
    padding-left: 44px;
    padding-bottom: 14px;
    height: 83px;
}

.messages_items_main_wrapper_user_img_info_wrapper {
    display: flex;
    align-items: center;
}


.messages_items_main_wrapper_user_img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.messages_items_main_wrapper_user_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.messages_items_main_wrapper_user_info_name {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.messages_items_main_wrapper_user_status_info {
    display: flex;
    align-items: center;
}

.messages_items_main_wrapper_user_status_info_text {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #888888;
}

.messages_items_main_wrapper_user_status_icon {
    background-color: #00FF0A;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    margin-right: 3px;
}

.messages_items_main_search_button {
    background: rgba(255, 255, 255, 0.07);
    border-radius: 34px;
    width: 40px;
    height: 40px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.messages_items_main_more_info_button {
    background: rgba(255, 255, 255, 0.07);
    border-radius: 34px;
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.messages_items_main_buttons_wrapper {
    display: flex;
    align-items: center;
}

.messages_items_main_wrapper_body {
    width: 100%;
    padding-left: 44px;
    padding-right: 44px;
    /*flex: 1;*/
    height: calc(100vh - 287px);
    padding-bottom: 42px;
    overflow-x: scroll;
    padding-top: 10px;
}

.messages_items_main_wrapper_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-right: 44px;
    margin-left: 44px;
    padding: 15px;
    height: 50px;
}

.messages_items_main_wrapper_footer_input_files_upload_box {
    display: flex;
    align-items: center;
}
.messages_items_main_wrapper_footer_input_files_upload {
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 21px;
}

.message_input_field {
    width: 90%;
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #8A8A8A;

}

.message_input_field::placeholder {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #8A8A8A;
}

.messages_items_main_wrapper_footer_icons_voice_buttons_wrapper {
    display: flex;
    align-items: center;
}


.messages_items_main_wrapper_footer_icons {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    margin-right: 27px;
    background: none;
    outline: none;
}

.messages_items_main_wrapper_footer_voice_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    background: none;
    outline: none;
}

.chat_item_parent {
    width: 100%;
}

.chat_item_child {
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    justify-content: flex-start;
}
.chat_item_child_img {
    width: 35px;
    height: 35px;
    margin-right: 8px;
}
.chat_item_child_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat_item_child_message_date_wrapper {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px 14px 14px 14px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
}
.chat_item_child_message_date_wrapper2 {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px 14px 14px 14px;
    padding: 10px;
    margin-bottom: 3px;
}
.chat_item_child_message {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    margin-right: 8px;
}

.chat_item_child_user_name {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #7B61FF;
    margin-bottom: 4px;
}

.chat_item_child_message_send_img_info_wrapper {
    display: flex;
    align-items: center;
}
.chat_item_child_message_send_img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    margin-right: 5px;
}

.chat_item_child_message_send_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat_item_child_message_send_img_info {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 3px;
}

.chat_item_child_message_send_img_info2 {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #A1AAB3;
}
.chat_item_child_message_date {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #A1AAB3;
}

.chat_item_child_message_send_img_size_info_date_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.chat_item_child2 {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 8px;
}

.chat_item_child2_name_info_wrapper {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px 14px 14px 14px;
    padding: 10px;
    margin-bottom: 3px;
}

.chat_item_child2_name {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #C8504F;
    margin-bottom: 4px;
}

.chat_item_child2_img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-left: 7px;
}
.chat_item_child2_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat_item_child_message_date_wrapper_send {
    width: 100%;
    display: flex;
    /*flex-direction: column;*/
    justify-content: flex-end;
    align-items: flex-end;
}

/*.chat_item_child_info_box {*/
/*    width: 100%;*/
/*    max-width: 483px;*/
/*}*/

.chat_item_child_message_date_img_main_wrapper {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px 14px 14px 14px;
    padding: 10px 0;
    margin-bottom: 3px;
}
.chat_item_child_img2 {
    width: 100%;
    /*max-width: 307px;*/
    height: 202px;
}
.chat_item_child_img2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat_item_child_message_date_wrapper3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px 10px;
}

@media (min-width: 1441px) and (max-width: 1700px) {
    .messages_items_main_wrapper_parent {
        padding-right: 160px!important;
    }
    .messages_item1 {
        margin-right: 160px!important;

    }
}
@media (min-width: 1341px) and  (max-width: 1440px) {
    .messages_items_main_wrapper_parent {
        padding-right: 130px!important;
    }
    .messages_item1 {
        margin-right: 130px!important;

    }
    .messages_items_main_wrapper_body {
        padding-left: 26px!important;
        padding-right: 26px!important;
    }
}
@media  (max-width: 1340px) {
    .messages_items_main_wrapper_parent {
        padding-right: 100px!important;
    }
    .messages_item1 {
        margin-right: 100px!important;

    }
    .messages_items_main_wrapper_body {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
}
