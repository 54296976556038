@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


#setNewsletter_select .css-13cymwt-control {
    background-color: unset!important;
    border: unset!important;
    /*min-height: unset!important;*/
}
#setNewsletter_select .css-1xc3v61-indicatorContainer svg {
    fill: #0165E1!important;
}
#setNewsletter_select .css-1u9des2-indicatorSeparator {
    display: none!important;
}
#setNewsletter_select .css-qbdosj-Input::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500!important;
    font-size: 18px!important;
    color: #0165E1!important;
}
#setNewsletter_select .css-1dimb5e-singleValue {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500!important;
    font-size: 18px!important;
    color: #0165E1!important;
}
#setNewsletter_select .css-1jqq78o-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500!important;
    font-size: 18px!important;
    color: #0165E1!important;
}
.newsletter_item_child2 {
    display: flex;
    align-items: center;
    flex: 1
}


#setNewsletter_select .css-1fdsijx-ValueContainer {
    /*display: none!important;*/
    padding: unset!important;
}


.newsletter_item_child_select_title {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #BE0000;
    margin-right: 5px;

}

.newsletter_item_child_select_title2 {
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #31BE00;
    margin-right: 5px;

}

/*.css-1nmdiq5-menu {*/
/*    width: 170px!important;*/
/*}*/

.container3 {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container3 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark3 {
    position: relative;
    height: 15px;
    width: 15px;
    border-radius: 3px;
    border: 1px solid #0165E1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

/*!* On mouse-over, add a grey background color *!*/
/*.container3:hover input ~ .checkmark3 {*/
/*    background-color: #0165E1;*/
/*}*/

/*!* When the checkbox is checked, add a blue background *!*/
/*.container3 input:checked ~ .checkmark3 {*/
/*    background-color: #0165E1;*/

/*}*/

.setNewsletter_wrapper {
    width: 100%;
}



/* Create the checkmark/indicator (hidden when not checked) */
.checkmark3:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container3 input:checked ~ .checkmark3:after {
    display: block;
}

/* Style the checkmark/indicator */
.container3 .checkmark3:after {
    content: '';
    background-image: url("../svg/news_check_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #0165E1;
    width: 15px;
    height: 15px;
    left: 50%;
    top: 9%;
    transform: translate(-50%, -12%)
}

.setNewsletter_wrapper {
    width: 100%;
    margin-top: 30px;
    /* margin-right: 30px; */
    overflow: scroll;
    height: calc(100vh - 97px);
    padding-bottom: 50px;
    padding-right: 30px;
}

.setNewsletter_items_wrapper {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 30px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.setNewsletter_item {
    width: 48%;
}

.setNewsletter_item_input_title_wrapper {
    width: 100%;
    margin-bottom: 30px;
}

.setNewsletter_item_input_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    margin-bottom: 10px;
}

.setNewsletter_item_input_field {
    width: 100%;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    padding: 14px 20px;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #323232;

}

.setNewsletter_item_input_field::placeholder {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #BCBCBC;
}

.setNewsletter_item_textarea_img_title_wrapper {
    width: 100%;
    margin-bottom: 24px;
}
.setNewsletter_item_textarea_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    margin-bottom: 7px;
}

.setNewsletter_item_textarea_img_field_wrapper {
    width: 100%;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    overflow: hidden;
}

.setNewsletter_item_textarea_img {
    background: #BCBCBC;
    height: 35px;
    padding-left: 24px;
    padding-right: 24px;
}

.setNewsletter_item_textarea_field {
    resize: none;
    width: 100%;
    border: none;
    cursor: pointer;
    outline: none;
    padding-top: 14px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 181px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #BCBCBC;
}
.setNewsletter_item_textarea_field::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #BCBCBC;
}

.setNewsletter_item_select {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}


.setNewsletter_item_select_title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #0165E1;

}

.setNewsletter_item_check_input_wrapper {
    width: 100%;
    margin-bottom: 31px;
}

.setNewsletter_item_calendar_wrapper {
    border: 2px solid #B2B8BE;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 30px;
    height: 190px;
}

.setNewsletter_item_hour_wrapper {
    border: 2px solid #B2B8BE;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 60px;
    height: 190px;
}

.setNewsletter_item_hour_title_btn_wrapper {
    border-bottom: 2px solid #B2B8BE;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 14px;
    margin-bottom: 10px;
}


.setNewsletter_item_calendar_title_btn_wrapper {
    border-bottom: 2px solid #B2B8BE;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 14px;
    margin-bottom: 10px;
}

.setNewsletter_item_calendar_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #B2B8BE;
}
.setNewsletter_item_hour_title {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #B2B8BE;
}
.setNewsletter_item_hour_delete_btn {
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #2B78E4;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
}


.setNewsletter_item_calendar_delete_btn {

    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #2B78E4;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;

}

.setNewsletter_item_buttons_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.setNewsletter_item_save_button {
    background: #EFEFEF;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    width: 48%;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}
.setNewsletter_item_send_button {
    background: #0165E1;
    border-radius: 10px;
    cursor: pointer;
    outline: none;
    width: 48%;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 50px;
}

@media (max-width: 1350px) {
    .setNewsletter_item_save_button {
        font-size: 15px!important;
    }
    .setNewsletter_item_send_button {
        font-size: 15px!important;
    }
    .setNewsletter_item_input_field {
        font-size: 16px!important;

    }
    .setNewsletter_item_input_field::placeholder {
        font-size: 16px!important;
    }
    .setNewsletter_item_textarea_field {
        font-size: 16px!important;

    }
    .setNewsletter_item_textarea_field::placeholder {
        font-size: 16px!important;

    }
    .setNewsletter_item_calendar_title {
        font-size: 16px!important;
    }
    .setNewsletter_item_hour_delete_btn {
        font-size: 16px!important;

    }
}
